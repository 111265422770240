import type { Api } from '@rialtic/api'
import type { AuthHeaders } from '@rialtic/types'
import type { AppType } from '@worker/auth0-edge-access'
import { hc } from 'hono/client'
import { createFetch } from 'ofetch'
import { useWorkspace } from '~/stores/workspace'

export const useEdgeAccessClient = (token?: string) => {
  const config = useRuntimeConfig().public

  const { $auth } = useNuxtApp()
  const workspace = useWorkspace()

  return hc<AppType>(<string>config.AUTH0_ACCESS_API_BASE_URL, {
    headers: {
      Authorization: `Bearer ${token || $auth.accessToken}`,
      'auth-provider': !$auth.accessToken ? 'auth0' : 'cognito',
      'x-rialtic-workspace-id': workspace.workspaceId,
    },
  })
}

export const useRpcClient = (token?: string) => {
  const config = useRuntimeConfig().public

  const { $auth } = useNuxtApp()
  const workspace = useWorkspace()

  return hc<Api.AppType>(<string>config.WORKERS_API_BASE_URL, {
    headers: {
      Authorization: `Bearer ${token || $auth.accessToken}`,
      'auth-provider': !$auth.accessToken ? 'auth0' : 'cognito',
      'x-rialtic-workspace-id': workspace.workspaceId,
    },
  })
}

export const useWorkersApi = () => {
  const config = useRuntimeConfig().public
  const { $auth, $auth0, $datadog } = useNuxtApp()
  const workspace = useWorkspace()

  const baseURL = <string>config.WORKERS_API_BASE_URL

  const getAuthHeaders = async (): Promise<AuthHeaders | undefined> => {
    let token = $auth.accessToken

    const { isAuthenticated, getAccessTokenSilently } = $auth0()

    let useAuth0Token = false

    if (!$auth.refreshToken && isAuthenticated.value) {
      try {
        token = await getAccessTokenSilently()

        useAuth0Token = !!token
      } catch (error) {}
    }

    if (!token && $auth.refreshToken) {
      try {
        token = await $auth.checkAndRefresh()
      } catch (error) {
        return
      }
    }

    return {
      Authorization: `Bearer ${token}`,
      'auth-provider': useAuth0Token ? 'auth0' : 'cognito',
      'x-rialtic-workspace-id': workspace.workspaceId,
    }
  }

  const client = $fetch.create({
    fetch: createFetch({
      fetch: globalThis.fetch,
      Headers: globalThis.Headers,
    }),
    baseURL,
    async onRequest({ options }) {
      const authHeaders = await getAuthHeaders()
      if (!authHeaders) {
        return
      }
      options.headers = {
        ...options.headers,
        ...authHeaders,
      }
    },
    async onResponseError({
      error,
      response,
      options: { baseURL, params, query },
    }) {
      if (response.status === 401) {
        try {
          await $auth.checkAndRefresh()
        } catch (error) {
          return
        }
      } else if (response.status === 400) {
        $datadog.addAction('fetch_400', { baseURL, error, params, query })
      } else {
        $datadog.addError(error, { baseURL, params, query })
      }
    },
  })

  const fetchPublic = $fetch.create({
    baseURL,
    fetch: createFetch({
      fetch: globalThis.fetch,
      Headers: globalThis.Headers,
    }),
    async onResponseError({
      error,
      response,
      options: { baseURL, params, query },
    }) {
      if (response.status === 400) {
        $datadog.addAction('fetch_400', { baseURL, error, params, query })
      } else {
        $datadog.addError(error, { baseURL, params, query })
      }
    },
  })

  return {
    baseURL,
    fetch: client,
    fetchPublic,
    getAuthHeaders,
  }
}
