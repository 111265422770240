
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "f2a5b81a-23c2-4aaa-b3b0-0d3b85d3c6b3"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/rialtic/workflows/console-ui/apps/console/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
